<template>
    <div class="message-text">
        <div v-if="message.mtype === 'waive-confirmed'" class="body">
            <div class="noti-title">
                {{ content.msg }}
            </div>
            <div class="noti-body">불가피한 사정으로 인정되어 수수료가 면제 처리되었습니다. 감사합니다.</div>
        </div>
        <div v-else class="body">
            <div class="noti-title">
                {{ content.msg }}
            </div>
            <div class="noti-body">
                {{ scheduleDate + ' ' + content.target_name }}님과의
                {{
                    content.penalty_type === '약속 취소 수수료' ? content.penalty_type : '약속 ' + content.penalty_type
                }}
                면제 요청이 반려되었습니다.
            </div>
            <div class="noti-blue-box">
                {{ content.waive_reject_reason }}
            </div>
            <div class="noti-button flex">
                <BottomButton
                    class="bottom-button-sub m-t-15 m-b-5"
                    @click="onClickSubmit"
                    label="수수료 납부하기"
                    :black-btn="true"
                    :non-fixed="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ActionWaiveConfirmed',
    props: ['message', 'item'],
    data: () => ({
        stats: [],
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        penaltyProduct() {
            return this.$store.getters.products.penalty
        },
        scheduleDate() {
            const date = new Date(this.content.date_confirmed)
            let month = date.getMonth() + 1
            let day = date.getDate()

            month = month < 10 ? '0' + month : month
            day = day < 10 ? '0' + day : day

            const formattedDate = `${month}월 ${day}일`

            return formattedDate
        },
    },
    methods: {
        async onClickSubmit() {
            console.log('aa', this.content.user_id)
            console.log('bb', this.content.schedule_id)

            const payload = {
                user_id: this.content.user_id,
                schedule_id: this.content.schedule_id,
            }

            const res = await scheduleService.getScheduleRequest(payload)
            console.log('ccccc', res.msg)
            if (res.msg === 'waived') {
                this.$modal.basic({
                    body: '취소 페널티가 면제되었습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else if (res.msg === 'done') {
                this.$modal.basic({
                    body: '이미 수수료를 납부했습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                // const product = this.penaltyProduct.filter(p => p.name === this.content.penalty_type)[0]
                this.$router.push({
                    name: 'PaymentCancelFeePage',
                    params: {
                        product: this.content,
                        scheduleDate: this.content.date_confirmed,
                        targetName: this.content.target_name,
                        scheduleId: this.content.schedule_id,
                    },
                })
            }
        },
    },
}
</script>
<style scoped lang="scss">
.body {
    padding: 9px 4px;
    .noti-tester {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        align-items: center;
    }
    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
    .noti-blue-box {
        margin-top: 12px;
        background: #f0f8ff;
        border-radius: 8px;
        color: #111111;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 24px;
        .noti-time {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
        }
    }

    ::v-deep .bottom-button > button {
        height: 40px !important;
    }
}
</style>
